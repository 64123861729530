.root {
  border-radius: var(--dimensions-border-radius-default);
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  > h4 {
    margin: 0 0 0.5rem 0;
  }
}
.info {
  background-color: var(--palette-info-background);
  color: var(--palette-info-main);
  > h4 {
    color: var(--palette-info-main);
  }
  border: 1px solid var(--palette-divider);
}
.success {
  background-color: var(--palette-background-secondary);
  color: var(--palette-success-main);
  border: 1px solid var(--palette-success-main);

  > h4 {
    color: var(--palette-success-main);
  }
}
.info {
  background-color: var(--palette-info-background);
  color: var(--palette-info-main);
  > h4 {
    color: var(--palette-info-main);
  }
}
.warning {
  background-color: var(--palette-warning-background);
  border: 1px solid var(--palette-divider);

  // border: 1px solid var(--palette-warning-main);
  color: var(--palette-warning-main);
  > h4 {
    color: var(--palette-warning-main);
  }
}
