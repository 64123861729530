.root {
  padding: 0;
  margin: 0;
  display: flex;

  > svg {
    color: #fecf04;
    width: 1rem;
    height: 1rem;
  }
}

.ratingLabel {
  color: var(--palette-primary-main);
  font-weight: 600;
  font-size: 0.85rem;
  margin-left: 5px;
  margin-top: 2px;
}
