@import 'styles/mixins';

.root {
  display: flex;
  gap: 150px;
  gap: 11%;
  flex-direction: column;

  @include sm {
    flex-direction: row;
  }
}

.rowTitle {
  margin: 0;
}
