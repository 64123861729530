@import 'styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  justify-content: center;
  align-items: flex-start;

  @include md {
    flex: 1 1 0px;
  }
}
