@import 'styles/mixins';

.root {
  display: inline-block;
  font-weight: 500;
  position: relative;

  &::after {
    content: '\203A';
    margin-left: 6px;
    position: absolute;
    right: -10px;
    top: -1px;
    transition: right var(--animation-duration) ease;
  }

  &:hover {
    text-decoration: underline;
    &::after {
      right: -13px;
    }
  }
}

.ctaContainer {
  margin-top: 28px;
  text-align: center;

  @include sm {
    text-align: left;
  }
}
