@import 'styles/mixins';

.checkboxLabel {
  display: flex;
  flex-shrink: 0;
  gap: 16px;
  text-align: left;
  line-height: 1.3;
  font-size: 0.75rem;
  padding-left: 10px;
  -webkit-tap-highlight-color: transparent;

  @include sm {
    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    .checkboxInput {
      &:not(:checked) {
        &::before {
          opacity: 0.15;
          background-color: var(--palette-text-primary);
        }
      }
    }
  }
}

.checkboxInput {
  -webkit-appearance: none;
  border: 1px solid var(--palette-text-primary);
  flex-shrink: 0;
  transition: all 200ms ease-in-out;
  appearance: none;
  margin: 0;
  color: currentColor;
  width: 26px;
  height: 26px;
  border-radius: 4px;
  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    opacity: 0;
    transform: opacity var(--animation-duration);
    transform-origin: bottom left;
    transition: all 200ms ease-in-out;
    background-color: CanvasText;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    background-color: var(--palette-secondary-main);
  }

  &:hover {
    cursor: pointer;
  }

  &:checked {
    border-color: var(--palette-secondary-main);
    background-color: var(--palette-secondary-main);

    &::before {
      opacity: 1;
      background-color: white;
    }
  }
}
