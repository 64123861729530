@import 'styles/mixins';

.root {
  width: 100%;
  & > form {
    width: 100%;
  }
}

.checkbox {
  margin: 20px 0 30px 0;
}

.mailcheck {
  font-size: 0.8rem;
  padding: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0 var(--dimensions-border-radius-default) 2px var(--dimensions-border-radius-default);
}
.subscript {
  margin: 0.25rem 0;
  font-size: 0.8rem;
  button {
    color: var(--palette-text-default);
    font-weight: bold;
    font-size: 0.8rem;
    &:hover {
      text-decoration: underline;
    }
  }
  .agreementButton {
    display: inline;
    font-weight: normal;
    color: var(--palette-secondary-main);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.overlay {
  background: var(--palette-background-default);
  max-width: 50rem;
  overflow: scroll;
}
.marketing {
  margin-top: 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  > p {
    flex: 1;
    margin-right: 1rem;
    margin: 0;
  }
}
.section {
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 0rem;
  }
}

.forgotPassword {
  color: var(--palette-text-default);
  float: left;
  margin: 30px 0 10px 14px;
  font-size: 0.8rem;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
}

.lowerMessage,
.lowerLink {
  font-size: 0.8rem;
}

.lowerMessage {
  margin: 20px 0 5px;
  display: block;

  @include sm {
    display: inline;
    margin-left: 8px;
    margin-right: 8px;
  }
}

.lowerLink {
  color: var(--palette-text-default);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
}
