@import 'styles/mixins';

$animationInTiming: 450ms;

.root {
  width: 100vw;
  height: 100vh;
  display: flex;
  backdrop-filter: blur(3px);
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.33);
  border: none;
  margin: 0;
  color: inherit;
  font-size: inherit;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 49;
  padding: 16px;
  animation: $animationInTiming fadeInOuter;
  animation-fill-mode: forwards;

  @include sm {
    padding: 32px;
  }
}

@keyframes fadeInOuter {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

.close {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--palette-background-secondary);
  position: absolute;
  top: 12px;
  right: 12px;
  width: 39px;
  height: 39px;
  border: 1px solid var(--palette-divider);
  padding: 0;
  margin: 0;
  cursor: pointer;
  z-index: 50;
  & > svg {
    height: 18px;
    height: 18px;
    color: var(--palette-text-secondary);
  }
}

.videoCloseBtn {
  top: -20px;
  right: -20px;
}

.contentOuterWrapper {
  width: 100%;
  border-radius: var(--dimensions-border-radius-card);
  max-height: 100%;
  background-color: var(--palette-background-default);
  box-shadow: 0px 2px 12px rgb(0, 0, 0, 0.4);
  display: flex;
  position: relative;
  overflow: visible;
  top: 0;
  // This transform allows us in specific cases to override the overflow: hidden of
  // .contentInnerWrapper by setting an inner child to position: static
  transform: scale(1);

  @include sm {
    min-width: calc(min(100vw, 368px));
    width: auto;
  }

  &.videoOuterWrapper {
    border-radius: 0;
  }
}

.contentInnerWrapper {
  overflow: hidden;
  display: flex;
  width: 100%;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  overscroll-behavior: contain;
  overflow-x: hidden;
}
