@import 'styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 20px;
  color: var(--palette-primary-main) !important;
  z-index: 1;

  @include sm {
    padding-top: 100px;
    padding-bottom: 0px;
    flex-direction: row;
    gap: 11%;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.hasTitle {
    padding-top: 0;
  }
}

.borderBottom {
  border-bottom: 1px dotted var(--palette-primary-main);

  @include sm {
    padding-bottom: 40px;
  }
}

.rowWrapper {
  position: relative;
  z-index: 0;
  margin-bottom: 10px;

  &::after {
    display: none;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0.25;
    background-image: url('/assets/tick_icon_green.svg'), url('/assets/tick_icon_green.svg'),
      url('/assets/dollar_icon_yellow.svg'), url('/assets/dollar_icon_yellow.svg'),
      url('/assets/dollar_icon_yellow.svg');
    background-repeat: no-repeat;
    background-position: 10% 12%, 40% 75%, 35% 9%, 95% 12%, 5% 75%;
    background-size: 45px, 30px, 60px, 75px, 40px;
  }
}

.column {
  margin-bottom: 30px;

  @include sm {
    margin-bottom: 0;
  }

  &.contentAlignedTop {
    justify-content: flex-start;
  }

  section {
    @include sm {
      margin-bottom: 40px;
    }
  }

  h1 {
    margin: 0 0 40px;
    @include londrina-title(large);

    @include sm {
      margin-top: 40px;
      margin-bottom: 80px;
    }
  }

  h2 {
    @include londrina-title(small);
    margin: 0 0 10px;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0;
    margin-bottom: 2rem;
  }

  p a {
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}

.isFirstRow {
  padding-top: 60px;
}

.mobileColumnOrderSecond {
  order: 2;

  @include sm {
    order: 0;
  }
}

.imageColumn {
  padding-bottom: 30px;
}

.hiddenColumn {
  display: none;
  @include md {
    display: flex;
  }
}

.mainContent {
  font-size: 1rem;
}

.rowTitle {
  @include londrina-title(small);
}
