.root {
  display: flex;
  color: var(--palette-text-primary);
  flex-direction: column;
}
.submit {
  width: 70%;
  max-width: 12.5rem;
  min-width: 9.25rem;
  margin-top: 25px;
  margin-bottom: 50px;
  -webkit-appearance: none;
  cursor: pointer;

  &.submitFullWidth {
    max-width: none;
    width: 100%;
  }
}
.buttonWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

:global(.formContainer) {
  .submit {
    margin-top: 40px;
  }
}
