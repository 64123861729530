@import 'styles/mixins';

.root {
  margin-bottom: 75px;
  display: flex;
  justify-content: center;
  padding: 16px;
  @include sm {
    padding: 60px 0;
  }
}

.formWidthContainer {
  width: 100%;
  max-width: 470px;
  position: relative;
}

.oinq {
  display: none;
  width: 180px;
  position: absolute;
  top: 140px;
  left: -140px;
  animation-name: oinqIn;
  animation-duration: 1300ms;

  @include md {
    display: block;
  }
}

@keyframes oinqIn {
  0% {
    left: 20px;
    top: 160px;
  }
  50% {
    left: 20px;
    top: 160px;
  }
  80% {
    left: -145px;
    top: 135px;
  }
  100% {
    left: -140px;
    top: 140px;
  }
}
