@import 'styles/mixins';

.outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 22px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 1;
  position: relative;
  width: 100%;
  margin-bottom: 80px;
  width: 100%;
}

.inner {
  background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, white);
  position: relative;
  padding: 0 20px 60px;
  width: 100%;
  right: -30px;
  bottom: -40px;
  margin-left: -60px;
  text-align: center;

  @include sm {
    padding-bottom: 0;
    padding-left: 35px;
    padding-right: 35px;
  }
}

.titleContainer {
  margin-bottom: 30px;
}

.formTitle {
  font-size: 1.4rem;
  margin: 0;
  font-weight: 600;
}

.formSubtitle {
  margin: 8px 0 24px;
  font-size: 0.9rem;
}
