@import 'styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  background: var(--palette-background-default);
  border-radius: var(--dimensions-border-radius-card);
  padding: 20px;
  box-shadow: var(--shadow-overlay);
  max-width: 600px;

  @include sm {
    padding: 30px;
  }
}
.body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--palette-error-background);
  padding: 12px 20px;
  border-radius: calc(var(--dimensions-border-radius-card) - 4px);

  p {
    line-height: 1.3;
  }
}
.button {
  align-self: center;
  margin-top: 26px;
}
