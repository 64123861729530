@import 'styles/mixins';

.root {
  padding-top: 0px;
  padding-bottom: 30px;

  @include sm {
    padding-bottom: 60px;
    padding-top: 20px;
  }
}

.title {
  display: none;
  font-weight: 600;
  @include londrina-title(small);

  @include sm {
    text-align: center;
    margin: 0 0 50px 0;
    display: block;
  }
}

.trustpilotLogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.trustPilotSmallWidgetContainer {
  padding-top: 20px;
}
