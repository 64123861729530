@import 'styles/mixins';
.mobileImage {
  width: 100%;
  display: inline-block !important;

  @include md {
    display: none !important;
  }
}
.desktopImage {
  width: 100%;

  display: none !important;

  @include md {
    display: inline-block !important;
  }
}
.close {
  margin-top: 0;
  @include md {
    margin-top: 10px;
  }
}
