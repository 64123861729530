@import 'styles/mixins';
.root {
  position: sticky;
  bottom: 2rem;
  z-index: 5;
  display: flex;
  align-self: center;
  max-width: 800px;
  justify-content: space-between;
  color: white;
  border-radius: var(--dimensions-border-radius-card);
  margin: 0 1rem 2rem 1rem;
  &:hover {
    cursor: pointer;
  }
}
.close {
  fill: var(--palette-text-secondary);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  padding: 0.25rem;
  background-color: var(--palette-background-secondary);

  position: absolute;
  top: 1rem;
  right: 1rem;
  transform: scale(1);
  transition: transform var(--animation-duration), background-color var(--animation-duration);
  &:hover {
    transform: scale(1.1);
    background-color: var(--palette-background-hover);
  }
}
