@import 'styles/mixins';

.appStoreLinks {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
  gap: 4%;

  & > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
    @include md {
      margin: 0 16px 0 0;
    }
  }
  & > a:last-child {
    margin-right: 0;
  }

  @include md {
    justify-content: flex-start;
    gap: normal normal;
  }
}

.appStoreLink {
  width: 48%;
  max-width: 180px;

  &:hover {
    .badgeContainer {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.28);
    }
  }

  @include md {
    max-width: 150px;
  }
}

.rating {
  padding: 0;
  margin: 0;
  align-self: center;
}

.badgeContainer {
  padding: 12px 28px 7px;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  transition: box-shadow 250ms ease-in-out;
  background-color: white;
  @include md {
    padding: 11px 25px 6px;
  }
}

.storeBadge {
  width: 100%;
}

.showOnMobileOnly {
  display: flex;
  @include md {
    display: none;
  }
}
