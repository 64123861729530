@import 'styles/mixins';

.signUpButton {
  margin-top: 30px;

  @include sm {
    max-width: 24rem;
  }

  @include md {
    display: none;
  }
}
